<template>
  <div>
    <div class="center1">
      <el-button @click="doLottery" type="primary">开始抽签</el-button>
    </div>
    <div style="margin-top: 20px">
      <el-row :gutter="20">
        <el-col :span="name == '团体' ? 2 : 4">
          <div class="center1" style="margin-bottom: 20px; color: green">
            参赛人员
          </div>
          <div v-if="type == '团体'">
            <el-table
              :data="summary.matchMembersAll"
              border
              style="width: 100%"
            >
              <el-table-column header-align="center" prop="team" label="单位" />
            </el-table>
          </div>
          <div v-if="type == '单打'">
            <el-table
              :data="summary.matchMembersAll"
              border
              style="width: 100%"
            >
              <el-table-column header-align="center" prop="name" label="姓名" />
              <el-table-column header-align="center" prop="team" label="单位" />
            </el-table>
          </div>
          <div v-if="type == '双打'">
            <el-table
              header-align="center"
              :data="summary.matchMembersAll"
              border
              style="width: 100%"
            >
              <el-table-column prop="name" label="姓名1">
                <template #default="scope">
                  {{ scope.row.name.split(",")[0] }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名2">
                <template #default="scope">
                  {{ scope.row.name.split(",")[1] }}
                </template>
              </el-table-column>
              <el-table-column prop="team" label="单位" />
            </el-table>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="center1" style="margin-bottom: 20px; color: red">
            参数
          </div>
          <div class="center1">
            <el-form label-position="left" label-width="auto">
              <el-form-item label="参赛人数">
                {{ summary.groupConfig.totalPeople }}</el-form-item
              >
              <el-form-item label="组数">
                {{ summary.groupConfig.groupCount }}</el-form-item
              >
              <el-form-item label="每组3人"
                >{{ summary.groupConfig.group3 }}
              </el-form-item>
              <el-form-item label="每组4人">
                {{ summary.groupConfig.group4 }}</el-form-item
              >
            </el-form>
          </div>
          <div class="center1" style="margin-bottom: 20px; color: orange">
            种子选手
          </div>

          <div class="center1">
            <el-table :data="summary.seedMembers" border style="width: 100%">
              <el-table-column label="种子号">
                <template #default="scope">
                  {{ scope.$index + 1 }}号种子
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" />
              <el-table-column prop="team" label="团队" />
            </el-table>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="center" style="margin-bottom: 20px">抽签结果-上半区</div>

          <div v-if="matchResultTop && matchResultTop.length > 0">
            <div
              class="center0"
              :key="'toparea' + i"
              v-for="(area, i) in matchResultTop"
            >
              <div style="margin-top: 20px; margin-right: 20px">
                {{ i + 1 }}/{{ summary.areas.length }}区
              </div>
              <div
                class="center0"
                :key="'topgroup' + j"
                v-for="(group, j) in area"
              >
                <el-table :data="group" border style="margin-left: 10px">
                  <el-table-column
                    v-if="type == '单打'"
                    prop="name"
                    label="姓名"
                  />
                  <el-table-column
                    v-if="type == '双打'"
                    prop="name"
                    label="姓名"
                    width="120"
                  >
                    <template #default="scope">
                      {{ scope.row.name.replaceAll(",", "/") }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="team"
                    :width="type == '团体' ? 200 : 120"
                    label="单位"
                  />
                </el-table>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="center" style="margin-bottom: 20px">抽签结果-下半区</div>

          <div v-if="matchResultBottom && matchResultBottom.length > 0">
            <div
              class="center0"
              :key="'toparea' + i"
              v-for="(area, i) in matchResultBottom"
            >
              <div style="margin-top: 20px; margin-right: 20px">
                {{ i + 1 + summary.areas.length / 2 }}/{{
                  summary.areas.length
                }}区
              </div>
              <div
                class="center0"
                :key="'topgroup' + j"
                v-for="(group, j) in area"
              >
                <el-table :data="group" border style="margin-left: 10px">
                  <el-table-column
                    v-if="type == '单打'"
                    prop="name"
                    label="姓名"
                  />
                  <el-table-column
                    v-if="type == '双打'"
                    prop="name"
                    label="姓名"
                    width="120"
                  >
                    <template #default="scope">
                      {{ scope.row.name.replaceAll(",", "/") }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :width="type == '团体' ? 200 : 120"
                    prop="team"
                    label="单位"
                  />
                </el-table>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from "vue";

import axios from "axios";
import _ from "lodash";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  summary: Object, // 参赛成员  一般有  Team Name等字段  有的只有Team字段
  type: String, // 单打 双打 团队
});

const matchResult = ref([]);
const matchResultTop = ref([]);
const matchResultBottom = ref([]);

const doLottery = () => {
  axios
    .post("https://chouqian.meihuabo.com/api/doLottery", {
      // .post("http://10.168.1.108:21082/doLottery", {
      name: props.summary.name,
      filename: localStorage.getItem("currentFile"),
    })
    .then((response) => {
      matchResult.value = response.data;
      if (response.data && response.data.length > 0) {
        const [firstHalf, secondHalf] = _.chunk(
          props.summary.areas,
          props.summary.areas.length / 2
        );

        matchResultTop.value = firstHalf;
        matchResultBottom.value = secondHalf;

        matchResultTop.value = [];
        matchResultBottom.value = [];
        for (let i = 0; i < firstHalf.length; i++) {
          let tmp = [];
          for (let j = 0; j < firstHalf[i].length; j++) {
            tmp.push(response.data[firstHalf[i][j] - 1]);
          }

          matchResultTop.value.push(tmp);
        }
        for (let i = 0; i < secondHalf.length; i++) {
          let tmp = [];
          for (let j = 0; j < secondHalf[i].length; j++) {
            tmp.push(response.data[secondHalf[i][j] - 1]);
          }

          matchResultBottom.value.push(tmp);
        }
        console.log(props.summary);
        console.log(matchResultTop, matchResultBottom);
      }
    })
    .catch((/*error*/) => {
      // console.error(error);
    });
};
</script>


<style scoped>
.center0 {
  flex-direction: row;
  display: flex;

  text-align: left;
  justify-content: left;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.center {
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}

.center1 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>