<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="1">随机抽签分组</el-menu-item>
      <!-- <el-menu-item index="2">第一阶段</el-menu-item>
      <el-menu-item index="3">第二阶段</el-menu-item> -->
    </el-menu>

    <div v-if="activeIndex == 1">
      <YumaoqiuSuijifenpei />
    </div>
  </div>
</template>
<script  setup>
import { ref } from "vue";
import YumaoqiuSuijifenpei from "./YumaoqiuSuijifenpei";
const activeIndex = ref("1");
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath);
};
</script>

<style scoped>
</style>