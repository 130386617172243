<template>
  <div>
    <el-row>
      <el-col :span="12">
        <!-- action="http://10.168.1.108:21082/chouqian" -->
        <el-upload
          :on-success="onUploadSuccess"
          class="upload-demo"
          drag
          action="https://chouqian.meihuabo.com/api/chouqian"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            拖拽excel文件到此处 <em>点击上传excecl文件</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              <!-- jpg/png files with a size less than 500kb -->
            </div>
          </template>
        </el-upload>
      </el-col>
      <el-col class="center" :span="12">
        <el-button @click="download" type="primary">下载抽签结果</el-button>
      </el-col>
    </el-row>

    <el-row style="margin-top: 100px" v-if="matches.length > 0">
      <el-col :span="24">
        <el-tabs v-model="activeName">
          <template v-for="match in matches">
            <el-tab-pane
              v-if="match.matchMembers && match.matchMembers.length > 0"
              :label="match.name"
              :name="match.name"
              :key="match.name"
            >
              <MatchLottery :summary="match" :type="parseType(match.name)" />
            </el-tab-pane>
          </template>

          <!-- <el-tab-pane
            v-if="danda.matchMembers && danda.matchMembers.length > 0"
            :label="danda.name"
            name="1"
          >
            <MatchLottery :summary="danda" type="单打" />
          </el-tab-pane>
          <el-tab-pane
            v-if="shuangda.matchMembers && shuangda.matchMembers.length > 0"
            :label="shuangda.name"
            name="2"
          >
            <MatchLottery :summary="shuangda" type="双打" />
          </el-tab-pane>
          <el-tab-pane
            v-if="team.matchMembers && team.matchMembers.length > 0"
            :label="team.name"
            name="3"
          >
            <MatchLottery :summary="team" type="团体" />
          </el-tab-pane> -->
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref } from "vue";
import MatchLottery from "./MatchLottery";

const activeName = ref("1");

const matches = ref([]);

const onUploadSuccess = (res) => {
  matches.value = res.matches;
  activeName.value = res.matches[0].name;

  // if (danda.value.matchMembers.length > 0) {
  //   activeName.value = "1";
  // } else if (shuangda.value.matchMembers.length > 0) {
  //   activeName.value = "2";
  // } else if (team.value.matchMembers.length > 0) {
  //   activeName.value = "3";
  // }

  localStorage.setItem("currentFile", res.filename);
};

const download = () => {
  location.href =
    "https://chouqian.meihuabo.com/api/download?filename=" +
    // "http://10.168.1.108:21082/download?filename=" +
    localStorage.getItem("currentFile");
};

const parseType = (name) => {
  if (name.indexOf("单打") >= 0) {
    return "单打";
  } else if (name.indexOf("双打") >= 0) {
    return "双打";
  } else if (name.indexOf("团体") >= 0) {
    return "团体";
  }
  return "";
};
</script>

<style scoped>
.upload-demo {
  width: 50%;
  height: 150px;
  margin: 20px;
}

.center {
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}
.center1 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>